<!--/**
* Author: ganqz
* Date: 2021-11-27 14:05
* Desc: Login
*/
-->
<template>
  <zmAppPage :show-footer="false" :show-header="false" :showHeaderLeft="true" title="登录" class="bc-white">
    <div class="extend pd15 login df-col">
      <div class="flex-1">
        <div class="logo"></div>
        <div class="form mt20 ptb10 bg-white">
          <van-field v-model="form.name" required label="员工姓名" placeholder="请输入员工姓名" />
          <!-- <van-field
            v-model="form.company"
            label="员工公司"
            placeholder="请选择员工公司"
            right-icon="arrow"
            required
            readonly
            @click="openAction"
          /> -->
          <van-field v-model="form.phone" type="phone" required label="手机号" placeholder="请输入手机号" />
        </div>
        <div class="df-center footer">
          <van-button round class="flex-1" type="info" @click="toRankList">排行榜</van-button>
          <van-button round class="flex-1" color="#ffc107" @click="onSubmit(1)">登录</van-button>
          <!-- <van-button round class="flex-1" type="warning" @click="onSubmit(2)">练习模式</van-button> -->
        </div>
        <!-- <van-action-sheet v-model="showAction" description="请选择公司" :actions="companyList" cancel-text="取消" @select="onSelect" /> -->
      </div>
      <!-- <div class="tc footer-logo">
        <img :src="footerLogo" class="footer-logo-img" alt="" />
      </div> -->
    </div>
  </zmAppPage>
</template>
<script>
import footerLogo from '@/assets/img/footer-logo.png';
import { PATH_RANK_LIST, PATH_HOME } from '@/router/routes/routePath';
import { companyList } from './const';
import * as auth from '@/utils/auth';

import { appSetToken } from '@/utils/src/utilsApp';
import { mapActions, mapGetters } from 'vuex';
// company: '江苏省国信集团有限公司本部',
//         name: 'agan',
//         phone: '15880431563'
export default {
  name: 'Login',
  components: {},
  data() {
    return {
      footerLogo,
      form: {
        company: '',
        name: '',
        phone: ''
      },
      companyList,
      showAction: false
    };
  },
  computed: {
    ...mapGetters(['token', 'userInfo']),
    redirect() {
      return this.$route.query.redirect;
    }
  },
  watch: {},
  created() {
    this.form = JSON.parse(JSON.stringify(this.userInfo));
  },
  methods: {
    ...mapActions('app', ['login', 'setUserInfo']),
    async onSubmit(type) {
      const isReLogin = this.form.name && this.form.phone;
      if (!this.token || isReLogin) {
        if (!this.form.name) {
          this.$msg.showMsgFail('请输入员工姓名!');
          return;
        }
        if (!this.form.phone) {
          this.$msg.showMsgFail('请输入手机号!');
          return;
        }
        if (this.form.phone) {
          let reg = /^1[3456789]\d{9}$/;
          if (!reg.test(this.form.phone)) {
            this.$msg.showMsgFail('请输入正确的手机号!');
            return;
          }
        }
        // if (!this.form.company) {
        //   this.$msg.showMsgFail('请选择公司!');
        //   return;
        // }
        const examId = auth.loc.get('examId', '');
        const res = await this.$apis.examsStart({ ...this.form, examId, type });
        // this.login({ ...this.form, examId }).then(res => {
        appSetToken(res.answerUserId);
        this.setUserInfo({ ...this.form, examId, token: res.answerUserId });
        this.$router.push({ path: PATH_HOME, query: { type } });
        // });
      } else {
        this.$router.push({ path: PATH_HOME, query: { type } });
      }
    },
    openAction() {
      this.showAction = true;
    },
    onSelect(v) {
      this.form.company = v.name;
      this.showAction = false;
    },
    toRankList() {
      this.$router.push({ path: PATH_RANK_LIST });
    }
  }
};
</script>
<style lang="scss" scoped>
.form {
  border-radius: 0.1rem;
}
.login {
  background: #ff0000;
  background-size: cover;
  .logo {
    display: inline-block;
    background: url('~@/assets/img/logo.jpg') no-repeat center;
    background-size: cover;
    width: 100%;
    height: 4.1rem;
    border-radius: 0.1rem;
  }
}
.footer {
  margin-top: 0.8rem;
}
.footer-logo {
  padding-bottom: 0.8rem;
  &-img {
    width: 2.86rem;
  }
}
</style>

export const companyList = [,
{name: '江苏省国信集团有限公司本部'},
{name: '江苏国信股份有限公司'},
{name: '江苏省投资管理有限责任公司'},
{name: '江苏省国际信托有限责任公司'},
{name: '江苏舜天国际集团有限公司'},
{name: '江苏省国信集团财务有限公司'},
{name: '江苏省天然气有限公司'},
{name: '江苏省新能源开发股份有限公司'},
{name: '江苏省国信信用融资担保有限公司'},
{name: '锦泰期货有限公司'},
{name: '恒泰保险经纪有限公司'},
{name: '江苏国信能源销售有限公司'},
{name: '江苏国信扬州发电有限责任公司'},
{name: '江苏新海发电有限公司'},
{name: '江苏淮阴发电有限责任公司'},
{name: '盐城发电有限公司'},
{name: '江苏射阳港发电有限责任公司'},
{name: '江苏国信靖江发电有限公司'},
{name: '江苏国信溧阳抽水蓄能发电有限公司'},
{name: '江苏沙河抽水蓄能发电有限公司'},
{name: '江苏国信协联能源有限公司'},
{name: '苏晋能源控股有限公司'},
{name: '苏晋塔山发电有限公司'},
{name: '苏晋朔州煤矸石发电有限公司'},
{name: '苏晋保德煤电有限公司'},
{name: '江苏省沿海输气管道有限公司'},
{name: '江苏国信液化天然气有限公司'},
{name: '江苏新能海力海上风力发电有限公司'},
{name: '江苏舜天股份有限公司'},
{name: '江苏省医药有限公司'},
{name: '江苏省国际人才咨询服务有限公司'},
{name: '江苏省软件产业股份有限公司'},
{name: '江苏省国信数字科技有限公司'},
{name: '江苏国信淮安新能源投资有限公司'},
{name: '江苏省国信创业投资有限公司'},
{name: '南京国信大酒店有限公司'},
{name: '南京丁山花园酒店有限公司'},
{name: '江苏省电影发行放映有限公司'}
];